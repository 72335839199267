<template>
  <div class="SidebarList2BigBox">
    <!-- v-snackbar的min-width据别人说有bug，所以必须在这里设置 :min-width="0"-->
    <div class="SnackbarBox">
      <v-snackbar
        v-model="sbFlag"
        :timeout="sbTimeout"
        :min-width="0"
        centered
        :color="sbColor"
      >
        {{ sbMsg }}
      </v-snackbar>
    </div>

    <v-list>
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Responses</v-list-item-title>
          </v-list-item-content>
        </template>

        <div v-for="(item2, index2) in responsesArray" :key="index2">
          <div
            class="item2Box"
            @click="clickNavToJson(item2, index2)"
            :style="item2Style(index2)"
          >
            <div class="RequestNameBox">
              {{ trimString(item2.kind) }}
            </div>
            <div class="TimestampBox">
              {{ $dayjs(parseInt(item2.RequestTimestamp) * 1000).fromNow() }}
            </div>
          </div>
        </div>
      </v-list-group>

      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>CopyPaste</v-list-item-title>
          </v-list-item-content>
        </template>
        <div v-for="(item3, index3) in arrClickedNodeContent" :key="index3">
          <div
            class="item3Box"
            :style="item3Style(index3)"
            @click="doCopy(item3, index3)"
          >
            {{ item3 }}
          </div>
        </div>
      </v-list-group>

      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item> Licence </v-list-item>
        <v-list-item> Contact </v-list-item>
        <v-list-item> Term of Use </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      sbFlag: false,
      sbMsg: "Copied",
      sbTimeout: 1000,
      idxSlcItem3: 999,
    };
  },
  computed: {
    ...mapState([
      "idxSlcItem2",
      "responsesArray",
      "responseJsonViewer",
      "arrClickedNodeContent",
    ]),

    sbColor() {
      if (this.sbMsg === "Copied") {
        return "rgba(76, 175, 80, 0.5)";
      } else {
        return "rgba(216, 27, 96, 0.5)";
      }
    },

    item2Style() {
      console.log("item2Style() a +1");

      return (idx) => {
        console.log("item2Style() b +1-idx->", idx);

        return {
          color:
            idx === this.idxSlcItem2
              ? "rgba(25, 118, 210,0.7)"
              : "rgba(0, 0, 0, 0.7)",
        };
      };
    },

    item3Style() {
      console.log("item3Style a +1");
      return (idx) => {
        console.log("item3Style b +1");
        return {
          color:
            idx === this.idxSlcItem3
              ? "rgba(25, 118, 210,0.7)"
              : "rgba(0, 0, 0, 0.7)",
        };
      };
    },
  },
  methods: {
    clickNavToJson(res, idx) {
      this.$store.commit("mutation_setIdxSlcItem2", idx);

      this.$store.commit("mutation_setResponseJsonViewer", res);

      this.$router.push(`/api/jsonviewer/${Date.now()}`);
    },

    async doCopy(item, idx) {
      const strItem = JSON.stringify(item);
      try {
        await this.$copyText(strItem);
        console.log("Copied");

        this.sbTimeout = 1000;
        this.sbMsg = "Copied";
      } catch (error) {
        console.error("Failed：", error);

        this.sbTimeout = 2000;
        this.sbMsg = "Failed";
      }
      this.sbFlag = true;
      this.idxSlcItem3 = idx;
    },

    trimString(str) {
      return str.split("#")[1];
    },
  },
};
</script>

<style scoped>
.methodBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.item2Box {
  /* background-color: pink; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.TimestampBox {
  font-size: 60%;
  opacity: 0.5;
}

.item2Box {
  color: rgba(241, 67, 218, 0.7);
  font-size: 16px;
  max-height: 40px;
  line-height: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px 15px 30px;
  /* 防止单词过长时在单词内部断开 */
  word-wrap: break-word;
  /* 当单词过长无法完全放入容器时，允许在单词中断开（适用于多字节字符和URL） */
  overflow-wrap: break-word;
  /* 防止文字换行，保持在一行内 */
  white-space: nowrap;
  /* 超出部分隐藏 */
  overflow: hidden;
  /* 超出部分显示省略号 */
  text-overflow: ellipsis;
}

.item3Box {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  max-height: 40px;
  line-height: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px 15px 30px;
  /* 防止单词过长时在单词内部断开 */
  word-wrap: break-word;
  /* 当单词过长无法完全放入容器时，允许在单词中断开（适用于多字节字符和URL） */
  overflow-wrap: break-word;
  /* 防止文字换行，保持在一行内 */
  white-space: nowrap;
  /* 超出部分隐藏 */
  overflow: hidden;
  /* 超出部分显示省略号 */
  text-overflow: ellipsis;
}
</style>
