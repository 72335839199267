import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackBarFlag: false,
    snackBarObj: null,
    arrIpPort: [],
    inputVmLink: '',
  },
  getters: {
    getSnackBarFlag: (state) => state.snackBarFlag,
  },
  mutations: {
    mutation_setSnackBarFlag(state, newValue) {
      state.snackBarFlag = newValue
    },
    mutation_setSnackBarObj(state, obj) {
      state.snackBarObj = obj
    },
    set_arrIpPort(state, obj) {
      state.arrIpPort = obj
    },
    set_SaveVmessLink(state, str) {
      state.inputVmLink = str
    },
  },
  actions: {},
  modules: {},
})
