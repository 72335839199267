<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1>Welcome to vDecoder</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomeView',

  data: () => ({
  }),
}
</script>
